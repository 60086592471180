import {
  ApplePayIcon,
  BancontactIcon,
  GooglePayIcon,
  IdealIcon,
  PayPalIcon,
  PaymentOptionsPropType,
  t,
} from '@vivino/js-web-common';
import CreditCardIcon from 'vivino-ui/icons/Payment/CreditCardIcon';
import GiroPayIcon from 'vivino-ui/icons/Payment/GiroPayIcon';
import { PaymentMethod } from '@stripe/stripe-js';

const TRANSLATIONS = {
  payWith: 'checkout.payment_options.pay_with',
  creditCard: 'checkout.payment_options.credit_card',
  iDealPay: 'checkout.ideal_bank_form.ideal_pay',
  zeroPayment: 'checkout.checkout_form.zero_payment',
};

export const APPLE_PAY = {
  name: PaymentOptionsPropType.ApplePay,
  iconComponent: ApplePayIcon,
  text: 'Apple Pay',
};

export const GOOGLE_PAY = {
  name: PaymentOptionsPropType.GooglePay,
  iconComponent: GooglePayIcon,
  text: 'Google Pay',
};

const CREDIT_CARD = {
  name: PaymentOptionsPropType.Stripe_CreditCard,
  iconComponent: CreditCardIcon,
  text: t(TRANSLATIONS.creditCard),
};

const IDEAL = {
  name: PaymentOptionsPropType.Stripe_iDeal,
  iconComponent: IdealIcon,
  text: t(TRANSLATIONS.iDealPay),
};

const GIROPAY = {
  name: PaymentOptionsPropType.Stripe_Giropay,
  iconComponent: GiroPayIcon,
  text: 'Giropay',
};

const BANCONTACT = {
  name: PaymentOptionsPropType.Stripe_Bancontact,
  iconComponent: BancontactIcon,
  text: 'Bancontact',
};

const PAYPAL = {
  name: PaymentOptionsPropType.Paypal,
  iconComponent: PayPalIcon,
  text: 'PayPal',
};

export const PaymentMethodType = {
  CARD: 'card',
};

export const getPaymentMethodWallet = (paymentMethod: PaymentMethod): string => {
  if (!paymentMethod || !isCreditCard(paymentMethod.type)) {
    return '';
  }
  if (paymentMethod.card?.wallet) {
    return paymentMethod.card.wallet?.type;
  }
  return '';
};

export const isCreditCard = (paymentMethodType: string): boolean =>
  paymentMethodType === PaymentMethodType.CARD;

export const isStripe = (provider: string): boolean => provider === PaymentOptionsPropType.Stripe;

export const PAYMENT_OPTION_PRESENTATION_BY_TYPE = {
  [PaymentOptionsPropType.Stripe_CreditCard]: CREDIT_CARD,
  [PaymentOptionsPropType.Stripe_iDeal]: IDEAL,
  [PaymentOptionsPropType.Stripe_Giropay]: GIROPAY,
  [PaymentOptionsPropType.Stripe_Bancontact]: BANCONTACT,
  [PaymentOptionsPropType.Paypal]: PAYPAL,
};
